const topMenu = () => {
    const header = document.querySelector('.header'),
          headerButton = document.querySelector('.header__button');

    function topMenuControl () {
        if (window.innerWidth > 767 ) {
            if (window.scrollY < 104) {
                header.style.cssText = `
                    margin-top: ${103 - window.scrollY}px;
                    background: transparent;
                `;
                if (headerButton.classList.contains('active')) {
                    headerButton.classList.remove('active');
                }
            } else {
                header.style.cssText = `
                    background: #262626;
                    margin-top: 0px;
                `;
                if (!headerButton.classList.contains('active')) {
                    headerButton.classList.add('active');
                }
            }
        } else {
            if (window.scrollY <= 0) {
                header.style.cssText = `
                    margin-top: ${0 - window.scrollY}px;
                    background: transparent;
                `;
                if (headerButton.classList.contains('active')) {
                    headerButton.classList.remove('active');
                }
            } else {
                header.style.cssText = `
                    background: #262626;
                    margin-top: 0px;
                `;
                if (!headerButton.classList.contains('active')) {
                    headerButton.classList.add('active');
                }
            } 
        }
        
    }
         
    topMenuControl ();

    window.addEventListener('scroll', () => {
        topMenuControl ();
    })
    
}

export default topMenu;