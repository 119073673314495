import sendForms from "./forms";

const validation = () => {
    const inputs = document.querySelectorAll('[name="phone"]'),
          buttons = document.querySelectorAll('[type="submit"]'),
          names = document.querySelectorAll('[name="name"]'),
          forms = document.querySelectorAll('form');

    let pass = false;

    function debounce(func, delay) {
        let timerId;
        
        return function() {
          const context = this;
          const args = arguments;
          
          clearTimeout(timerId);
          
          timerId = setTimeout(function() {
            func.apply(context, args);
          }, delay);
        };
    }
    function passer () {
        function preventDefault (e) {
                e.preventDefault();
            }
            forms.forEach(form => {
                    form.addEventListener('submit', preventDefault);
            })
            
            buttons.forEach((button, i )=> {
                if ((inputs[i].value.length < 19) || (names[i].value.length < 3)) {
                    button.classList.add('not-send');
                    pass = false;
                } else {
                    pass = true;
                    button.classList.remove('not-send');
                    forms[i].removeEventListener('submit', preventDefault); 
                }
            })
            if (pass) {
                sendForms();
        }
    }
    passer ();

    const debouncedHandleInput = debounce(passer, 500);
    inputs.forEach(elem => {
        elem.addEventListener('input', debouncedHandleInput);
        elem.addEventListener('focus', debouncedHandleInput);
    });
    names.forEach(elem => {
        elem.addEventListener('input', debouncedHandleInput);
        elem.addEventListener('focus', debouncedHandleInput);
    });

    

}

export default validation;