const tabs = () => {
    const tabButtons = document.querySelectorAll('.portfolio__tab'),
          tabItems = document.querySelectorAll('.portfolio__tab-item');
        
    tabButtons.forEach((button, i) => {
        button.addEventListener('click', () => {
            if (!button.classList.contains('active')) {
                tabButtons.forEach(elem => {
                    elem.classList.remove('active');
                })
                tabItems.forEach(tab => {
                    tab.classList.add('fade-reverse');
                    setTimeout(() => {
                        tab.classList.add('hide');
                        tab.classList.remove('fade-reverse');
                    }, 200);
                    
                })
                button.classList.add('active');
                setTimeout(() => {
                    tabItems[i].classList.remove('hide');
                }, 200);
                
            }
        })
    })

}

export default tabs;