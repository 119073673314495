import modalClose from './modalClose';

function sendForms () {
    const forms = document.querySelectorAll('form');

    forms.forEach(form => {
        sendMessage(form);
    })
    
    function sendMessage(form) {
        const message = {
            loading: 'svg/spinner.svg',
            failure: 'Щось пішло не так...',
        };
    
        postData(form);
    
        function postData(form) {
            form.addEventListener('submit', (e) => {
                e.preventDefault();

                
    
                const name = form.querySelector('[name="name"]'),
                      phone = form.querySelector('[name="phone"]');
    
                let validationMessage = form.querySelector('.valdation-message');
    
                if (validationMessage) {
                    validationMessage.remove();
                }
                const validationMesageText = {
                    name : "Ім'я має бути не менше 3 літер",
                    nonLetter: "Можна вводити лише літери",
                    phone : "Ведіть телефон повністю"
                };
                      
                validationMessage = document.createElement('div');
                validationMessage.classList.add('valdation-message');
                validationMessage.style.color = 'red';
    
                if (name.value.match(/\d+/) ) {
                    validationMessage.innerHTML = validationMesageText.nonLetter;
                    return name.insertAdjacentElement('afterend', validationMessage);
                }
            
                if (name.value.length < 3) {
                    validationMessage.innerHTML = validationMesageText.name;
                    return name.insertAdjacentElement('afterend', validationMessage);
                } 
                if (phone.value.length < 10) {
                    validationMessage.innerHTML = validationMesageText.phone;
                    return phone.insertAdjacentElement('afterend', validationMessage);
                } 
                
                const statusMessage = document.createElement('img');
                statusMessage.src = message.loading;
                statusMessage.style.cssText = `
                    display: block;
                    margin: 0 auto;
                `;
                form.insertAdjacentElement('afterend', statusMessage);
    
                // FETCH block START
                const formData = new FormData(form);
        
                fetch('server.php', {
                        method: 'POST',
                        body: formData
                    })
                    .then(data => {
                        form.reset();
                        statusMessage.remove();
                    }).catch(() => {
                        showThanksModal(message.failure);
                    }).finally(() => {
                        // window.location.replace(`${window.location.href}thanks.html`);
                        window.location.href = `${window.location.href}thanks.html`
                    });            
                });
        };
        function showThanksModal(message) {
            const prevModalDialog = document.querySelector('.modal__wrapper');
    
            prevModalDialog.classList.add('hide');
           
    
            const thanksModal = document.createElement('div');
            thanksModal.classList.add('modal__wrapper');
            
            thanksModal.innerHTML = `
                <h3 class="modal__header">${message}</h3>
            `;
            document.querySelector('.modal .wrapper').append(thanksModal);
            setTimeout(() => {
                thanksModal.remove();
                prevModalDialog.classList.remove('hide');
                modalClose(document.querySelector('.deletable'));
            }, 4000);
        }
    }
}

export default sendForms;