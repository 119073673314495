import modalOpen from "./modalOpen";
import modalClose from "./modalClose";

const modal = () => {
    const buttons = document.querySelectorAll('[data-modal]'),
          modal = document.querySelector('.modal'),
          modalCloser = document.querySelector('.modal__close');

    let deletable;
         
    buttons.forEach(button => {
        button.addEventListener('click', () => {
            modalOpen(button);
          
        })
    })
    modalCloser.addEventListener('click', () => {
        deletable = document.querySelector('.deletable');
        modalClose(deletable);
    })
    modal.addEventListener('click', (e) => {
        deletable = document.querySelector('.deletable');
        if (e.target == modal) {
            modalClose(deletable);
        } 
    })

   
}

export default modal;