// import modals from './modules/sampleDir';
import topMenu from "./modules/topMenu";
import tabs from "./modules/tabs";
import modal from "./modules/modal";
import openList from "./modules/openList";
import sliders from "./modules/sliders";
import phoneMask from "./modules/phoneMask";
import validation from "./modules/validation";


window.addEventListener('DOMContentLoaded', () =>{
    "use strict";

    topMenu();
    tabs();
    modal();
    openList();
    sliders();
    phoneMask();
    validation();


    document.querySelectorAll('button').forEach(elem => {
        elem.addEventListener('click', (e) =>{
            e.preventDefault();
        })  
    })


});
