function modalClose (del) {
    const modal =  document.querySelector('.modal'),
          header = document.querySelector('.header'),
          forms = document.querySelectorAll('form');
          
        //   headerPadding = window.getComputedStyle(header).paddingRight;
    modal.style.display = '';
    document.body.style.overflow  = '';
    document.body.style.marginRight = ``;
    header.style.paddingRight = '0px';
    forms.forEach(form => {
        form.reset();
    })
    del.remove();
}

export default modalClose;