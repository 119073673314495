import { tns } from "tiny-slider";

const sliders = () => {
function individual () {
	let a = tns({
	container: '.individual__slider',
	controls: true,
	nav: false,
	autoplay: true,
	autoplayButtonOutput: false,
	speed: 300,
	loop: false,
	// mouseDrag: true,
	items: 3,
	slideBy: 3,
	rewind: true,
	responsive: {
		320: {
			disable: true
		},
		991: {
			disable:false,
			items: 3
		}
	}
	});

window.addEventListener('resize', () => {
	if (window.innerWidth <= 991 ) {
	if (a.isOn) {
		a.destroy();
	}  
	
} else {
	if (a.isOn) {
		a.destroy();
	}            
	a  = tns({
			container: '.individual__slider',
			controls: true,
			nav: false,
			autoplay: true,
			autoplayButtonOutput: false,
			speed: 300,
			loop: false,
			// mouseDrag: true,
			items: 3,
			slideBy: 3,
			rewind: true,
			responsive: {
			320: {
				disable: true
			},
			991: {
				disable:false,
				items: 3
			}}
		});
		document.querySelector('.tns-inner').style.margin = '0 auto';	
}
})
document.querySelector('.tns-inner').style.margin = '0 auto';
}
individual ();

function tabSlider () {
	if (window.innerWidth < 992) {
		const tabHolder = document.querySelector('.portfolio__tabs'),
			tabs = document.querySelectorAll('.portfolio__tab');
			

	let a;

	tabs.forEach((tab, i)=> {
		a = tns({
			container: `.portfolio__tab-item${i+1}`,
			controls: false,
			nav: true,
			navPosition: 'bottom',
			autoplay: true,
			autoplayButtonOutput: false,
			speed: 300,
			loop: false,
			mouseDrag: true,
			items: 1,
			slideBy: 1,
			rewind: true,
		});
	})

	const tnsNav = document.querySelectorAll('.tns-nav');
	tabs.forEach((tab, i) => {
		if (tab.classList.contains('active')) {
			tnsNav[i].style.display = 'flex';
		} else {
			tnsNav[i].style.display = 'none';
		}
			
	})

	tabHolder.addEventListener('click', (e) => {
		if (e.target.classList.contains('active')) {
			tabs.forEach((tab, i) => {
				if (tab.classList.contains('active')) {
					tnsNav[i].style.display = 'flex';
				} else {
					tnsNav[i].style.display = 'none';
				}
					
			})
		}
	})
	}
	}
	tabSlider ();

	function productionSlider () {
		if (window.innerWidth < 992) {
			let a = tns({
				container: `.production__galery`,
				controls: false,
				nav: true,
				navPosition: 'bottom',
				autoplay: true,
				autoplayButtonOutput: false,
				speed: 300,
				loop: false,
				mouseDrag: true,
				items: 1,
				slideBy: 1,
				rewind: true,
			});
		}
		
	}
	productionSlider ();

	function popularSlider () {
		const popularItems = document.querySelectorAll('.popular__slider');

		popularItems.forEach((item, i)=> {
			tns({
				container: `.popular__slider${i+1}`,
				controls: true,
				nav: false,
				autoplay: true,
				autoplayButtonOutput: false,
				speed: 300,
				loop: false,
				mouseDrag: true,
				items: 1,
				slideBy: 1,
				rewind: true,
			});
		})
	}
	popularSlider ();


	function nextPrev () {
		document.querySelectorAll('[data-controls]').forEach(control => {
			control.innerHTML = '';
		})
	}
	nextPrev ();
	
}

export default sliders;