const openList = () => {
    const openButton = document.querySelector('[data-opener]'),
          items = document.querySelectorAll('.popular__item');

    openButton.addEventListener('click', (e) => {
        e.target.remove();
        items.forEach(item => {
            item.classList.remove('hide');
        })
    })
}

export default openList;