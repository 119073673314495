import shiftCorection from "./shiftCorection";

const modalOpen = (dat) => {
    const modal = document.querySelector('.modal'),
          header = document.querySelector('.header'),
          scroll = shiftCorection(),
          headerPadding = window.getComputedStyle(header).paddingRight,
          headerScroll = parseInt(headerPadding) + scroll,
          modalCloser = document.querySelector('.modal__close'),
          submitButton = document.querySelector('.modal input[type="submit"]');


    modal.style.display = 'flex';
    document.body.style.cssText  = `overflow: hidden;`;
    document.body.style.marginRight = `${scroll}px`;
    header.style.paddingRight = `${headerScroll}px`; 

    function modalInnerHtml (html, butonValue, value) {
        const div = document.createElement('div');
        div.innerHTML = html
        div.classList.add('deletable');
        modalCloser.insertAdjacentElement('afterend', div);  
        submitButton.setAttribute('value', butonValue);

        const input = document.querySelector('#modal [name="theme"]');
        input.setAttribute('value', value)
        
    }

    // if (dat == 'presentation') {
    //     modalInnerHtml (
    //     `<h2 class="modal__label">Відправимо <span class='green'> презентацію компанії</span> на зручний месенджер</h2>`
    //     , 'Отримати презентацію')
    // }
    try {
        switch (dat.getAttribute('data-modal')) {
            case 'presentation': 
            modalInnerHtml (
                `<h2 class="modal__label">Відправимо <span class='green'> презентацію компанії</span> найближчим часом</h2>`
                , 'Отримати презентацію', 'Відправити презентацію');
                break;
            case 'cost': 
                modalInnerHtml (
                    `<h2 class="modal__label">Розрахуйте вартість <span>модульного будинку</span> з фахівцем </h2>
                    <div class="modal__descr">Передзвонимо найближчим часом</div> 
                    <div class="modal__divider"></div>`
                    , 'Розрахувати вартість', 'Розрахувати вартість');
                break;
            case 'guaranties': 
                modalInnerHtml (
                    `<h2 class="modal__label">Консультація спеціаліста <span>щодо договору
                    на будівництво</span> будинка</h2>
                    <div class="modal__descr">Проконсультуємо найближчим часом</div> 
                    <div class="modal__divider"></div>`
                    , 'Консультація щодо договору', 'Консультація щодо договору');
                break;
            case 'popular': 
                    const moduleName = dat.parentElement.parentElement.children[1].children[0].innerHTML;
                modalInnerHtml (
                    `<h2 class="modal__label">Отримати детальну інформацію про модуль<br> 
                    "<span>${moduleName}</span>"</h2>
                    <div class="modal__divider"></div>
                    <div class="modal__descr">Передзвонимо та проконсультуємо по даному модулю</div> 
                    `
                    , 'Отримати інформацію', `Надати інформацію щодо модуля ${moduleName}`);
                break;
            case 'consulting': 
            modalInnerHtml (
                `<h2 class="modal__label">Отримати консультацію щодо <span>модульних будинків</span> від фахівця </h2>
                <div class="modal__descr">Проконсультуємо найближчим часом</div> 
                <div class="modal__divider"></div>`
                , 'Отримати консультацію', 'Консультація щодо будинків від фахівця');
                break;
            case 'ready': 
                modalInnerHtml (
                    `<h2 class="modal__label">Отримати консультацію щодо <span>готових модулей</span> від фахівця </h2>
                    <div class="modal__descr">Проконсультуємо найближчим часом</div> 
                    <div class="modal__divider"></div>`
                    , 'Отримати консультацію', 'Консультація щодо готових модулів');
                    break;
            case 'custom': 
                modalInnerHtml (
                    `<h2 class="modal__label">Отримати консультацію щодо <span>модулів під замовлення</span> від фахівця </h2>
                    <div class="modal__descr">Проконсультуємо найближчим часом</div> 
                    <div class="modal__divider"></div>`
                    , 'Отримати консультацію', 'Консультація щодо модулів під замовлення');
                    break;
            default: console.log('Thank you');            
        }
    } catch (e) {};
    
    
}

export default modalOpen;